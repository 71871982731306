import React, { memo, useEffect, useState } from "react";

export const pad = (n) => (n > 9 ? `${n}` : `0${n}`);

export const durationDisplay = (counter) => {
  const days = ~~(counter / 86400);
  const remain = counter - days * 86400;
  const hrs = ~~(remain / 3600);
  const min = ~~((remain - hrs * 3600) / 60);
  const sec = ~~(remain % 60);
  return `${hrs > 0 ? `${pad(hrs)}:` : ""}${pad(min)}:${pad(sec)}`;
};

const CallDuration = () => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const timeout = setInterval(() => setDuration((e) => e + 1), 1000);
    return () => {
      clearInterval(timeout);
    };
  }, []);

  return durationDisplay(duration);
}


export default memo(CallDuration)