import { Button } from "antd";
import { useUser } from "context/UserContext";
import React from "react";

const ScreenLockAccount = () => {
  const userContext = useUser();
  return (
    <div
      className="d-flex align-items-center justify-content-center w-100"
      style={{ height: "100vh", background: "transparent" }}
    >
      <div>
        <div
          className="text-white bg-danger d-flex align-items-center justify-content-center mx-auto mb-3 pb-2"
          style={{
            height: 60,
            width: 60,
            borderRadius: 9999,
            fontSize: 40,
          }}
        >
          x
        </div>
        <p
          style={{ fontSize: 20, fontWeight: 500, textAlign: "center" }}
          className="px-4"
        >
          Tài khoản của bạn đã bị khoá. Vui lòng liên hệ CSKH để được hỗ trợ.
        </p>
        <div className="w-100 d-flex justify-content-center">
          <Button
            onClick={userContext.signOut}
            className="mt-4 profile__button mx-auto"
          >
            Đăng xuất
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ScreenLockAccount;
