import React, { memo, useEffect, useMemo, useReducer, useRef } from "react";
import { Modal, Switch, message } from "antd";
import "./style.scss";
import { CloseOutlined, PhoneFilled } from "@ant-design/icons";
import { safePlay } from "helper/common";
import CallDuration from "./CallDuration";
import { requestAccessMicrophone } from "helper/common";

const playAudio = (ele, src) => {
  ele.src = src;
  ele.preload = "auto";
  ele.addEventListener("canplay", () => {
    safePlay(ele);
  });
};

const defaultCallState = {
  incoming: false,
  connected: false,
  busy: false,
  error: null,
  videoEnable: false ,
  remoteVideoEnable: false,
  localVideoEnable: false,
};

// const acessToken = process.env.REACT_APP_STRINGEE_TEST_TOKEN || "eyJjdHkiOiJzdHJpbmdlZS1hcGk7dj0xIiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiJTSy4wLm1FeEFKMzkxQ0lZYld3c2FyeHF0QndOSEFSRnRTRy0xNjg2Nzk5NzMwIiwiaXNzIjoiU0suMC5tRXhBSjM5MUNJWWJXd3NhcnhxdEJ3TkhBUkZ0U0ciLCJleHAiOjE2ODkzOTE3MzAsInVzZXJJZCI6InVzZXIyIn0.H40IOMntDSHISrwZnvh7uFgLm9ptKXK2FpGnE5MHAdw";

const CallOnline = ({ token }) => {
  const caller = useRef(null);
  const audioRef = useRef(new Audio());

  const remoteVideoRef = useRef(null);
  const localVideoRef = useRef(null)

  const [callState, setCallState] = useReducer(
    (prev, cur) => ({ ...prev, ...cur }),
    defaultCallState
  );

  const onReject = () => {
    if (audioRef.current) audioRef.current.pause();
    caller.current.reject(() => {
      caller.current = null
      setCallState(defaultCallState);
    });
  };

  const onAnswer = () => {
    audioRef.current.pause();
    caller.current.answer();
  };

  const onHangup = () => {
    onReject();
    audioRef.current.pause();
    caller.current.hangup();
  };

  const onSwitchVideo = (value) => {
    if (value) caller.current.upgradeToVideoCall();
    caller.current.sendInfo({ requestVideo: value, client: true }, function (res) {
      console.log('switchVoiceVideoCall', res);
      setCallState({ localVideoEnable: value })
  });
  }

  const events = {
    info:(info) => {
      console.log('on info:' + JSON.stringify(info));
      if (info?.admin) {
        setCallState({ remoteVideoEnable: info?.requestVideo })
      }
      // if ("requestVideo" in info ) setCallState({ videoEnable: info?.requestVideo })
    },
    error: (error) => {
      console.log({ error, reasone: error.reason });
      if (error?.reason === "GET_USER_MEDIA_ERROR") {
        message.error(
          "Bạn chưa cấp quyền truy cập mic, vui lòng cấp quyền và tải lại trang."
        );
      } else {
        message.error("Xảy ra lỗi khi nhận cuộc gọi, vui lòng thử lại");
      }
      onReject();
    },
    addlocalstream: (stream) => {
      console.log("on addlocalstream", stream);
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = null;
        localVideoRef.current.srcObject = stream;
      }
    },
    addremotestream: (stream) => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = null;
        remoteVideoRef.current.srcObject = stream;
      }
    },
    mediastate: (state) => {
      console.log("on mediastate", state);
      if (state.code === 1) setCallState({ connected: true }); // Connected
      if (state.code === 2) {
        // Remote user disconnect
        onHangup();
      }
    },
    signalingstate: (signals) => {
      const signalAction = (signal) => {
        if (signal.code === 6) onReject();
        if (signal.code === 5) {
          setCallState({ busy: true });
          setTimeout(onReject, 3000);
        }
      };
      if (Array.isArray(signals)) {
        signals.forEach(signalAction);
      } else {
        signalAction(signals);
      }
    },
    otherdevice: (data) => {
      console.log("on otherdevice:" + JSON.stringify(data));

      if (
        (data.type === "CALL_STATE" && data.code >= 200) ||
        data.type === "CALL_END"
      ) {
        onReject();
      }
    },
  };

  useEffect(() => {
    if (window.StringeeUtil.isWebRTCSupported() && !!token) {
      const client = new window.StringeeClient();
      client.connect(token);

      client.on("connect", () => console.log("Stringee connected"));
      client.on("authen", function (res) {
        requestAccessMicrophone();
        console.log("on authen 1: ", res);
      });
      client.on("otherdeviceauthen", function (data) {
        console.log("otherdeviceauthen: ", data);
      });

      client.on("incomingcall", (incomingcall) => {
        if (!incomingcall.fromInternal || caller.current) return;
        caller.current = incomingcall;
        setCallState({ incoming: true });
        caller.current.ringing(function (res) {
          playAudio(audioRef.current, "/assets/audio/ringtone.mp3");
        });
        Object.keys(events).forEach((k) => caller.current.on(k, events[k]));
      });

      client.on("disconnect", function () {
        if (audioRef.current) audioRef.current.pause();
        console.log("disconnected - 1");
      });
    }
    return () => {
      caller.current = null;
    };
  }, [token]);

  const callDescription = useMemo(() => {
    if (callState.connected) return "Cuộc gọi đang diễn ra";
    if (callState.busy) return "Người dùng bận";
    return "Cuộc gọi sẽ bắt đầu ngay khi bạn chấp nhận";
  }, [callState]);

  return (
    <Modal
      className="call-modal"
      footer=""
      width={350}
      mask={false}
      closable={false}
      centered
      bodyStyle={{ borderRadius: 8 }}
      visible={callState.incoming}
      destroyOnClose
      onCancel={onReject}
    >
      <div className="avatar">
        <img
          alt="Admin"
          src="https://tenten.vn/tin-tuc/wp-content/uploads/2022/09/2-6.png"
        />
      </div>
      <h4 className="text-center">Admin đang gọi cho bạn</h4>
      <p className="h6 opacity-50 text-center">{callDescription}</p>

      {callState.connected && <fieldset className="d-flex align-items-center justify-content-center" style={{ gap: 6 }}>
        <label>{callState.localVideoEnable ? 'Mở' : 'Tắt'} video</label>
        <Switch value={callState.videoEnable} onChange={onSwitchVideo} />
      </fieldset>}
    

      <div className="video-container mt-2">
        <video ref={localVideoRef} style={{ height: callState.localVideoEnable ? 220: 0  }} autoPlay playsInline />
        <video ref={remoteVideoRef} style={{ height: callState.remoteVideoEnable && !callState.localVideoEnable ? 220: 0  }} autoPlay playsInline />
      </div>
      

      <div className="text-center">
        {callState.connected && <CallDuration />}
      </div>
      <div
        className="d-flex align-items-center justify-content-center mt-1"
        style={{ gap: 10 }}
      >
        {callState.connected && (
          <div className="btn">
            <button className="cancel" onClick={onHangup}>
              <CloseOutlined />
            </button>
            <span>Tắt máy</span>
          </div>
        )}

        {!callState.connected && (
          <>
            <div className="btn">
              <button className="cancel" onClick={onReject}>
                <CloseOutlined />
              </button>
              <span>Từ chối</span>
            </div>
            <div className="btn">
              <button className="approve" onClick={onAnswer}>
                <PhoneFilled />
              </button>
              <span>Chấp nhận</span>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default memo(CallOnline)