import { handleGetAppConfigurationSuccess } from 'actions/appAction';
import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SystemConfiguration from 'services/systemConfiguration';

export const SystemContext = createContext(null);

export function SystemProvider({ children }) {
  const system = useSelector(state => state.app?.config);
  const dispatch = useDispatch();

  function handleGetSAppConfigs() {
    SystemConfiguration.systemConfigurationFind().then(result => {
      const { error, data } = result;
      if (!error) {
        dispatch(handleGetAppConfigurationSuccess(data))
      }
    })
  }

  useEffect(() => {
    handleGetSAppConfigs();
  }, []);

  return (
    <SystemContext.Provider value={{ system }}>
      {children}
    </SystemContext.Provider>
  )
}

export function useSystem() {
  return useContext(SystemContext);
}