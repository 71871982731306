import classNames from 'classnames';
import { userFullName } from 'context/UserContext';
import { userAvatar } from 'context/UserContext';
import React from 'react';

export default function UserAvatar({ user, vertical, dark, onClick }) {
  return (
    <div className={classNames('d-flex', 'align-items-center', 'position-relative', {
      'flex-column': vertical,
      'justify-content-center': vertical,
    })}>
      <img className={classNames('img-rounded', { 'img-rounded--vertical': vertical, 'pointer': !!onClick })} src={userAvatar(user)} alt="" onClick={onClick} />
      <div className={classNames('px-2 position-relative', { 'text-center': vertical })}>
        <p className={classNames('fw-semibold mb-0', 'text-center', {
          'mt-1': vertical,
          'h6': vertical,
          'text-light': vertical && !dark,
          'text-dark': dark,
        })}>{userFullName(user)}</p>
        <p style={{ fontSize: 14, color: '#536885' }}>{user?.phone_number || "xxx-xxxx-xxxx"}</p>
      </div>
    </div>
  );
}
