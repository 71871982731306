/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from "antd";
import { routes } from "App";
import { HomeFilled } from "assets/icons";
import { ShoppingFilled } from "assets/icons";
import { ProfileFilled } from "assets/icons";
import { WalletFilled , Phone} from "assets/icons";
import { Shopping, Profile, Home, Wallet } from "assets/icons";
import React, { memo, useMemo } from "react";
import Zalo from "assets/images/zalo.png";
import style from "./style.module.scss";
import { useSystem } from "context/SystemContext";

const WidgetContact = () => {
  const { system } = useSystem();
  return (
    <div
      className="d-flex"
      style={{ gap: 8, position: "absolute", bottom: 60, right: 10 }}
    >
      <Tooltip title="Hotline">
        <a href={`tel:${system?.hotlineNumber}`}>
          <img src="/ChatIcon.png" height="auto" width={35} alt="HomeCredit" />
        </a>
      </Tooltip>
      <Tooltip title="Zalo">
        <a target="_blank" href={system?.zaloUrl}>
          <img
            style={{ borderRadius: 4, overflow: "hidden" }}
            src={Zalo}
            alt="Bộ phận giải đáp trong giờ hành chính (Từ T2-CN)"
            height="auto"
            width={35}
          />
        </a>
      </Tooltip>
    </div>
  );
};

const MenuNavigation = ({ location, onRedirect }) => {
  const { pathname } = location;
  const isRemoteUrl = !!window?.location?.href?.includes?.("remote");

  const menu = [
    {
      icon: Home,
      filled: HomeFilled,
      label: "Trang chủ",
      path: routes.home.path,
    },
    {
      icon: Wallet,
      filled: WalletFilled,
      label: "Ví tiền",
      path: routes.wallet.path,
    },
    // {
    //   icon: Shopping,
    //   filled: ShoppingFilled,
    //   label: "Dịch vụ",
    //   path: routes.serviceCustomer.path,
    // },
    {
      icon: Phone,
      filled: Phone,
      label: "CSKH",
      path: routes.chatSupport.path,
      isExternal: routes.chatSupport.isExternal,
    },
    {
      icon: Profile,
      filled: ProfileFilled,
      label: "Tôi",
      path: routes.myProfile.path,
    },
    
  ];

  const currentIndex = useMemo(
    () =>
      menu.findIndex(
        (ele) => ele.path.split("/")?.[1] === pathname?.split("/")?.[1]
      ),
    [pathname]
  );

  if (isRemoteUrl) return null;

  return (
    <nav
      className={`${style.nav} d-flex align-items-center bg-white p-0 fixed-bottom m-auto`}
      style={{ maxWidth: 380, height: 60 }}
    >
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ width: "100%", padding: "0 23px", position: "relative" }}
      >
        {menu.map(({ icon: Icon, filled: Filled, label, path, isExternal }, index) => (
          <div
            className={style.nav__item}
            role="tab"
            onClick={() => {
              if (isExternal) {
                window.open(path)
              } else {
                onRedirect(path)
              }
            }}
            key={label}
          >
            {currentIndex === index ? <Filled /> : <Icon />}
            <p
              style={{
                fontWeight: 400,
                fontSize: 11,
                color: currentIndex === index ? "#da2028" : "#657C97",
              }}
            >
              {label}
            </p>
          </div>
        ))}
        {/* <WidgetContact /> */}
      </div>
    </nav>
  );
};

export default memo(MenuNavigation);
