
import Loader from "components/Loader"
import WelcomePage from "Page/WelcomPage"
import React, { lazy, Suspense } from "react"
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Layout from './../src/Page/Layout'
import Autologin from "Page/Autologin"
import LoginSuccess from "Page/LoginSuccess"

const Home = lazy(() => import("./../src/Page/Home"));
const Register = lazy(() => import("./../src/Page/Register"));
const ConnectWallet = lazy(() => import("./../src/Page/ConnectWallet"));
const Login = lazy(() => import("./../src/Page/Login"));
const Wallet = lazy(() => import("./Page/Wallet"))
const ServiceCustomer = lazy(() => import("./Page/ServiceCustomer"))
const PayContract = lazy(() => import("./Page/ServiceCustomer/PayContact"))
const LoanCondition = lazy(() => import("./Page/ServiceCustomer/LoanConditions"))
const Disbursement = lazy(() => import("./Page/ServiceCustomer/Disbursement"))
const MyProfile = lazy(() => import("./Page/Profile"))
const MyLoan = lazy(() => import("./Page/Profile/MyLoan"))
const LoanStatus = lazy(() => import("./Page/Profile/LoanStatus"))
const AccountInfomation = lazy(() => import("./Page/Profile/AccountInfomation"))
const PersonalData = lazy(() => import("./Page/Profile/AccountInfomation/PersonalData"))
const VerifyAccount = lazy(() => import("./Page/Profile/AccountInfomation/VerifyAccount"))
const ContactInfo = lazy(() => import("./Page/Profile/AccountInfomation/ContactInfo"))
const BankInfo = lazy(() => import("./Page/Profile/AccountInfomation/PersonalAccount"))
const ExtraData = lazy(() => import("./Page/Profile/AccountInfomation/ExtraData"))
const Contract = lazy(() => import("./Page/Contract"))
const Introduce = lazy(() => import("./Page/Introduce"))
const Chat = lazy(() => import("./Page/Chat"))

export const routes = {
  contract: {
    path: "/contract",
    component: Contract,
    isAuth: true
  },
  introduce: {
    path: "/introduce",
    component: Introduce,
    isAuth: true
  },
  login: {
    path: "/login",
    component: Login,
    isAuth: false,
    label: "Đăng nhập",
  },
  register: {
    path: "/register",
    component: Register,
    isAuth: false,
    label: "Đăng ký",
  },
  wallet: {
    path: "/wallet",
    component: Wallet,
    isAuth: true,
  },
  serviceCustomer: {
    path: "/service-customer",
    component: ServiceCustomer,
    isAuth: true,
  },
  chatSupport: {
    path: "https://tk2-asia.support-online.chat/widget?website_token=B4yiJJU1L77oGQFjWdUwYLUM",
    component: ServiceCustomer,
    isAuth: true,
    isExternal: true,
  },
  payContract: {
    path: "/service-customer/pay-contract",
    component: PayContract,
    isAuth: true,
  },
  disbursement: {
    path: "/service-customer/disbursement",
    component: Disbursement,
    isAuth: true,
  },
  loanCondition: {
    path: "/service-customer/loan-condition",
    component: LoanCondition,
    isAuth: true,
  },
  myProfile: {
    path: "/me",
    component: MyProfile,
    isAuth: true,
  },
  myLoan: {
    path: "/me/my-loan",
    component: MyLoan,
    isAuth: true
  },
  loanStatus: {
    path: "/me/loan-status",
    component: LoanStatus,
    isAuth: true
  },
  accountInfo: {
    path: "/me/infomation",
    component: AccountInfomation,
    isAuth: true
  },
  personalData: {
    path: "/me/infomation/data",
    component: PersonalData,
    isAuth: true
  },
  verifyAccount: {
    path: "/me/infomation/verify",
    component: VerifyAccount,
    isAuth: true
  },
  contactInfo: {
    path: "/me/infomation/contact",
    component: ContactInfo,
    isAuth: true
  },
  personalAccount: {
    path: "/me/infomation/bank",
    component: BankInfo,
    isAuth: true
  },
  extraData: {
    path: "/me/infomation/extra",
    component: ExtraData,
    isAuth: true
  },
  chat: {
    path: "/chat",
    component: Chat,
    isAuth: true,
    hideFooter: true
  },
  autologin: {
    path: '/autologin',
    component: Autologin,
    blank: true
  },
  loginSuccess: {
    path: '/loginSuccess',
    component: LoginSuccess,
    blank: true
  },
  connectWallet : {
    path: '/connect-wallet',
    component: ConnectWallet,
    isAuth: true
  },
}

function App() {
  const isInitLoad = useSelector(state => state.member ? state.member.isInitLoad : true);

  
  routes.home = {
    path: "/",
    component: Home,
    isAuth: true,
    label: "Trang chủ",
  }
  return isInitLoad ? <WelcomePage/> : 
   (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          {Object.keys(routes).map((key, index) => {
            if (routes[key].isAuth) {
              return (
                <Route
                  key={index}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <Layout
                      {...props}
                      hideFooter={routes[key]?.hideFooter}
                      isAuth={routes[key].isAuth}
                      isMenuItem={routes[key].isMenuItem}
                      isSubMenu={routes[key].isSubMenu}
                      isSubItem={routes[key].isSubItem}
                      Component={routes[key].component}
                      showFooterMobile={routes[key].showFooterMobile}
                      className={`${routes[key].className || 'HOME'}`} />
                  )} />
              );
            } else if (!routes[key].isAuth) {
              return (
                <Route
                  key={index}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <Layout
                      {...props}
                      blank={routes[key].blank}
                      isAuth={routes[key].isAuth}
                      Component={routes[key].component}
                      showFooterMobile={routes[key].showFooterMobile}
                      className={routes[key].isHeader ? "HOME" : "LOGIN"} />
                  )} />
              );
            }
          })}
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
