import { useUser } from "context/UserContext"
import moment from "moment"
import { useEffect, useRef } from "react"
import AppUsers from "services/apppUsers"
import { setDefaultHeaders } from "services/request"

export const useAutoUpdateActive = () => {
    const {user} = useUser()
    const timer = useRef()

    const update = () => {
        AppUsers.updateInfoUser({ id: user.id, data: { web_actived_at: Number(moment().format("YYYYMMDDHHmm")) } }, true)
    }
    
    useEffect(() => {
        if (user?.id) {
            setDefaultHeaders({ authorization: `Bearer ${user.token}` });
            update()
            timer.current= setInterval(update, 120000)
        }
        return () => {
            clearInterval(timer.current)
        }
    }, [user?.id])
}