import * as actionTypes from "../constants/member"

const handleSignin = (data) => {
    return ({
        type: actionTypes.USER_LOGIN,
        data: data
    })
} 

const handleSignout = (id) => ({
    type: actionTypes.USER_RESET,
    data: id
})

const handleUpdateDetail= (data) => ({
    type: actionTypes.USER_DETAILS_UPDATE,
    data: data
})

export {
    handleSignin,
    handleSignout,
    handleUpdateDetail
}