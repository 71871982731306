import Loader from "components/Loader";
import { useEffect, useMemo } from "react"
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom"
// import qs from "query-string";

export default function LoginSuccess () { 
    const history = useHistory()
    const [isVisible, setIsVisible] = useState(true);
    // const { search } = useLocation()

    // const { username, password } = useMemo(() => qs.parse(search), [search])

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(false)
            history.push('/')
        }, 2000);
        
    }, [])

    return (
        <>
            {isVisible ? <Loader /> : null}
        </>
        
    )
}