import {
  USER_LOGIN,
  USER_DETAILS_UPDATE,
  USER_RESET,
} from "../constants/member";
import { INIT_FINISH } from "../constants/app";
import { getLatestUser } from "helper/common";
import { updateLocalUser } from "helper/common";
import { removeLocalUser } from "helper/common";

let initialState = {
  isInitLoad: true,
  data: getLatestUser(true),
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN: {
      if (action.data) {
        const currentTime = new Date().toISOString()
        const allUser = JSON.parse(localStorage.getItem("data"));
        const payload = { ...action.data, isUsing: true, lastActive: currentTime} 
        if (!allUser?.length)
        localStorage.setItem("data", JSON.stringify([payload]));
        else {
          const userIndex = allUser?.findIndex?.(
            (u) => u?.id === action.data.id
          );
          console.log({ userIndex })
          if (userIndex > -1) allUser[userIndex] = payload;
          else allUser.push(payload);
          window.localStorage.setItem("data", JSON.stringify(allUser));
        }
        return {
          ...state,
          data: { ...payload },
        };
      }
      return {};
    }
    case USER_DETAILS_UPDATE:
      updateLocalUser(state.data.id, action.data )
      return { ...state, data: { ...state.data, ...action.data} };
    case USER_RESET: {
      removeLocalUser(state.data.id)
      return { ...state, data: null };
    }
    case INIT_FINISH: {
      return {
        ...state,
        isInitLoad: false,
      };
    }
    default:
      return state;
  }
}
