import { message } from 'antd'
import Request from './request'

export default class AppUsers {

  static async getDetailUserById(data = {}, noMessage = true) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'Loans/user/findByPhone',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async changePasswordUser(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/changePasswordUser',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async changeSecondPasswordUser(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/changeSecondaryPassword',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async updateInfoUser(data = {}, noMessage = false) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'Loans/user/updateLoan',
        data
      }).then((result = {}) => {
        const { statusCode, data, message:error } = result
        if (statusCode === 200) {
          if(!noMessage) message.success('Cập nhật thành công!')
          return resolve({ isSuccess: true, data })
        } else {
          if(!noMessage) message.error('Cập nhật thất bại!')
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async drawMoney (data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'Loans/user/withdrawLoan',
        data
      }).then((result = {}) => {
        const { statusCode, data, message:error } = result

        if(error === "WRONG_CODE") {
          // return resolve({ isSuccess: false, error: 'Nhập mã rút tiền không chính xác!' })
          message.error('Nhập mã rút tiền không chính xác!')
        }

        if (statusCode === 200) {
          message.success('Rút tiền thành công!')
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message: error })
        }
      })
    })
  }

  static async requestVerifyKYC(data = {}) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/submitIdentity',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
}