import React, { useEffect, useMemo, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"
import qs from "query-string";
import { Spin } from "antd"
import LoginService from "services/loginService"
import { useDispatch } from "react-redux"
import { handleSignin } from "actions"

export default function Autologin () {
    const { search } = useLocation()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { username, password } = useMemo(() => qs.parse(search), [search])

    useEffect(() => {
        if (!username || !password || Number.isNaN(-username)) {
            history.push('/')
        } else { 
            setLoading(true)
            LoginService.Signin({ phonenum: username, password }).then((result) => {
                const { isSuccess, data } = result;
                setLoading(false);
                if (isSuccess) dispatch(handleSignin(data));
                history.push("/")
            })
        }
    }, [])

    return  (
        <div style={{ height: '100vh' }} className="d-flex align-items-center justify-content-center">
            {loading && <Spin />}
        </div>
    )
}